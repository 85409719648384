import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebookF,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function Footer() {

  return (
    <>
      {/* <!-- Footer Start --> */}
      <div
        className="container-fluid bg-dark text-light footer mt-5 py-5"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4 footerheading">Our Office</h4>
              <p className="mb-2">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="me-3" />
                FA-05/9, Vikas Business Centre, Lake City Mall, 1st floor, Kapurbawdi, Thane(W), 400601
              </p>
              <p className="mb-2">
                <FontAwesomeIcon icon={faPhoneAlt} className="me-3" />
                022-44511316
              </p>
              <p className="mb-2">
                <FontAwesomeIcon icon={faEnvelope} className="me-3" />
                info@upholic.in
              </p>
              <div className="d-flex pt-2">
                <a
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  href=""
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  href=""
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  href=""
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
                <a
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  href=""
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6"
            >
              <h4 className="text-white mb-4 footerheading">Services</h4>
              <Link className="btn btn-link" to="/comingsoon">
                Financial Planning
              </Link>
              <Link className="btn btn-link" to="/popup">
                PMS Services
              </Link>
              <Link className="btn btn-link" to="/comingsoon">
                Life Insurance
              </Link>
              <Link className="btn btn-link" to="/comingsoon">
                Loans
              </Link>
              <Link className="btn btn-link" to="/popup">
                Stock Advisory
              </Link>
            </div>
            <div
              className="col-lg-3 col-md-6"
            >
              <h4 className="text-white mb-4 footerheading">Quick Links</h4>
              <Link className="btn btn-link" to="/about">
                About Us
              </Link>
              {/* <a className="btn btn-link" href="">
                Contact Us
              </a> */}
              <Link className="btn btn-link" to="/popup">
                Our Services
              </Link>
              <Link className="btn btn-link" to="/comingsoon">
                Terms & Condition
              </Link>
              <Link className="btn btn-link" to="/comingsoon">
                Support
              </Link>
            </div>
            <div
              className="col-lg-3 col-md-6"
            >
              <h4 className="text-white mb-4 footerheading">Stay Connected</h4>
              {/* <!-- <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p> --> */}
              <div className="position-relative w-100">
                <input
                  className="form-control bg-white border-0 w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Your email"
                />
                <button
                  type="button"
                  className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                >
                  SignUp
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer End --> */}
    </>
  );
}

export default Footer;
