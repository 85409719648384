import React, { useState, useEffect } from "react";
import "./Pms_stock_entry.css";
import axios from "axios";

function Pms_stock_entry() {
  const [formData, setFormData] = useState({
    user_id: "",
    stock: "",
    sector: "",
    quantity: "",
    average_price: "",
    value_of_cost: "",
    percentage_changes: "",
    stock_invest_date: "",
    current_market_price: "",
    value_at_current_market_price: "",
    unrealizedPNL: "",
    realizedPNL: "",
    exit_date: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [accordionOpen, setAccordionOpen] = useState(false);

  // Function to handle form data input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Input changed: ${name} = ${value}`);

    const updatedFormData = { ...formData, [name]: value };

    // Perform calculations dynamically for fields like quantity and price
    if (name === "quantity" || name === "average_price") {
      const value_of_cost =
        parseFloat(updatedFormData.quantity || 0) *
          parseFloat(updatedFormData.average_price || 0) || "";
      updatedFormData.value_of_cost = value_of_cost;
      console.log("Calculated Value of Cost:", value_of_cost);
    }

    if (
      name === "current_market_price" ||
      name === "quantity" ||
      name === "average_price"
    ) {
      const valueOfCost =
        parseFloat(updatedFormData.quantity || 0) *
          parseFloat(updatedFormData.average_price || 0) || 0;

      const valueAtCurrentMarketPrice =
        parseFloat(updatedFormData.quantity || 0) *
          parseFloat(updatedFormData.current_market_price || 0) || 0;

      updatedFormData.value_of_cost = valueOfCost || "";
      updatedFormData.value_at_current_market_price =
        valueAtCurrentMarketPrice || "";

      updatedFormData.unrealizedPNL =
        valueAtCurrentMarketPrice - valueOfCost || "";

      updatedFormData.percentage_changes =
        valueOfCost > 0
          ? (
              (parseFloat(updatedFormData.unrealizedPNL || 0) / valueOfCost) *
              100
            ).toFixed(2)
          : "";

      updatedFormData.realizedPNL = "0"; // Adjust this logic as necessary for realized PNL

      console.log("Calculated Unrealized PNL:", updatedFormData.unrealizedPNL);
      console.log(
        "Calculated Percentage Changes:",
        updatedFormData.percentage_changes
      );
    }

    setFormData(updatedFormData);
  };

  // Function to fetch data when user_id changes
  useEffect(() => {
    const fetchDataByUserId = async () => {
      if (formData.user_id.trim() === "") return;

      console.log(`Fetching data for User ID: ${formData.user_id}`); 
      try {
        const response = await axios.get(
          `https://www.upholic.in/Upholic_Api/fetchstocks.php?user_id=${formData.user_id}`
        );

        if (response.data.success) {
          console.log("Data fetched successfully:", response.data.data);

          // Ensure the fetched data is correctly mapped to formData
          const fetchedData = response.data.data;
          console.log("Fetched Data:", response.data.data); // Verify the structure

          // Update formData with fetched values
          setFormData({
            user_id: fetchedData.user_id || formData.user_id,
            stock: fetchedData.stock || "",
            sector: fetchedData.sector || "",
            quantity: fetchedData.quantity || "",
            average_price: fetchedData.average_price || "",
            value_of_cost: fetchedData.value_of_cost || "",
            percentage_changes: fetchedData.percentage_changes || "",
            stock_invest_date: fetchedData.stock_invest_date || "",
            current_market_price: fetchedData.current_market_price || "",
            value_at_current_market_price:
              fetchedData.value_at_current_market_price || "",
            unrealizedPNL: fetchedData.unrealizedPNL || "",
            realizedPNL: fetchedData.realizedPNL || "",
            exit_date: fetchedData.exit_date || "",
          });

          setSuccessMessage("Data fetched successfully.");
          setErrorMessage("");
        } else {
          console.log("User ID not found.");
          setErrorMessage("User ID not found.");
          setSuccessMessage("");
          setFormData({
            user_id: formData.user_id,
            stock: "",
            sector: "",
            quantity: "",
            average_price: "",
            value_of_cost: "",
            percentage_changes: "",
            stock_invest_date: "",
            current_market_price: "",
            value_at_current_market_price: "",
            unrealizedPNL: "",
            realizedPNL: "",
            exit_date: "",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage(
          error.response?.data?.message || "An unexpected error occurred."
        );
        setSuccessMessage("");
      }
    };

    fetchDataByUserId();
  }, [formData.user_id]); // useEffect triggers when user_id changes

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted with data:", formData);
    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/insertstocks.php",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Server response:", response.data);
      setSuccessMessage("Data inserted successfully.");
      setFormData({
        user_id: "",
        stock: "",
        sector: "",
        quantity: "",
        average_price: "",
        value_of_cost: "",
        percentage_changes: "",
        stock_invest_date: "",
        current_market_price: "",
        value_at_current_market_price: "",
        unrealizedPNL: "",
        realizedPNL: "",
        exit_date: "",
      });
      setErrorMessage("");
    } catch (error) {
      console.error("Error submitting data:", error);
      setErrorMessage(
        error.response?.data?.message || "An unexpected error occurred."
      );
      setSuccessMessage("");
    }
  };

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
    console.log("Accordion toggled:", accordionOpen);
  };

  return (
    <>
      <div className="viewall-container">
        <div className="section">
          <div className="section-title" onClick={toggleAccordion}>
            NAV
          </div>
          {accordionOpen && (
            <>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
              <form className="investment-form" onSubmit={handleSubmit}>
                <div className="investment-row">
                  <label>User ID</label>
                  <input
                    type="text"
                    name="user_id"
                    value={formData.user_id}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="investment-row">
                  <label>Stock</label>
                  <select
                    id="stock"
                    name="stock"
                    value={formData.stock}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select a stock</option>
                    <option value="Adani Enterprises">Adani Enterprises</option>
                    <option value="Axis Bank">Axis Bank</option>
                    <option value="Adani Ports & SEZ">Adani Ports & SEZ</option>
                    <option value="TCS">TCS</option>{" "}
                    {/* Ensure "TCS" is present */}
                  </select>
                </div>
                <div className="investment-row">
                  <label>Sector</label>
                  <input
                    type="text"
                    name="sector"
                    value={formData.sector}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="investment-row">
                  <label>Quantity</label>
                  <input
                    type="number"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="investment-row">
                  <label>Average Price</label>
                  <input
                    type="number"
                    step="0.01"
                    name="average_price"
                    value={formData.average_price}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="investment-row">
                  <label>Value of Cost</label>
                  <input
                    type="text"
                    name="value_of_cost"
                    value={formData.value_of_cost}
                    readOnly
                  />
                </div>
                <div className="investment-row">
                  <label>Percentage Changes</label>
                  <input
                    type="text"
                    name="percentage_changes"
                    value={formData.percentage_changes}
                    readOnly
                  />
                </div>
                <div className="investment-row">
                  <label>Stock Invest Date</label>
                  <input
                    type="date"
                    name="stock_invest_date"
                    value={formData.stock_invest_date}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="investment-row">
                  <label>Current Market Price</label>
                  <input
                    type="number"
                    step="0.01"
                    name="current_market_price"
                    value={formData.current_market_price}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="investment-row">
                  <label>Value at Current Market Price</label>
                  <input
                    type="text"
                    name="value_at_current_market_price"
                    value={formData.value_at_current_market_price}
                    readOnly
                  />
                </div>
                <div className="investment-row">
                  <label>Unrealized PNL</label>
                  <input
                    type="text"
                    name="unrealizedPNL"
                    value={formData.unrealizedPNL}
                    readOnly
                  />
                </div>
                <div className="investment-row">
                  <label>Realized PNL</label>
                  <input
                    type="text"
                    name="realizedPNL"
                    value={formData.realizedPNL}
                    readOnly
                  />
                </div>
                <div className="investment-row">
                  <label>Exit Date</label>
                  <input
                    type="date"
                    name="exit_date"
                    value={formData.exit_date}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="investment-row">
                  <button type="submit" className="edit-button">
                    Insert
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Pms_stock_entry;
