import React, { useState } from "react";
import "./Pms_dashboard.css";
// import Pms_stock_edit_update from "../pms_stock_edit_update/Pms_stock_edit_update";
import Pms_login_data from "../pms_login_entry/Pms_login_data";
import Pms_insert_user_kyc from "../pms_insert_user_kyc/Pms_insert_user_kyc";
import Pms_stock_entry from "../pms_stock_entry/Pms_stock_entry";
import Pms_chart_entry from "../pms_chart_entry/Pms_chart_entry";
import Admin_sidebar_links from "../admin_sidebar_links/Admin_sidebar_links";
import Deposit_Data_Dashboard from "../depsoite_data_dashboard/Deposit_Data_Dashboard";

function Pms_dashboard() {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    console.log("Accordion toggled"); // Add this line to verify the function is called
    setAccordionOpen(!accordionOpen);
  };

  return (
    <>
      <Admin_sidebar_links />
      <Deposit_Data_Dashboard />
      <div className="pms_dashboard_area">
        {/* UL List */}
        <ul className="pms_dashboard_circles">
          {Array(10)
            .fill()
            .map((_, index) => (
              <li key={index}></li>
            ))}
        </ul>
        {/* Accordion Card */}
        <div className="Pms_dashboard_card">
          <div
            className="Pms_dashboard_section_title"
            onClick={toggleAccordion}
          >
            PMS Stocks
          </div>
          {accordionOpen && (
            <div className="accordion-content">
              {/* <Pms_stock_edit_update /> */}
              <Pms_login_data />
              <Pms_insert_user_kyc />
              <Pms_stock_entry />
              {/* <Pms_chart_entry /> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Pms_dashboard;
