import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ element, isAuthenticated, isLoading }) {
  if (isLoading) {
    // Optional: Show a loading spinner or message while authentication is being checked
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" />;
  }

  // Render the protected component if authenticated
  return element;
}

export default ProtectedRoute;
