import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cookies from "universal-cookie";
import ProtectedRoute from "./components/common/ProtectedRoute";

// Importing all pages
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Sign_up from "./pages/sign-up/Sign_up";
import Dashboard_Main from "./dashboard_/dashborad_main/Dashboard_Main";
import Popup from "./pages/popup/Popup";
import Coming_Soon from "./dashboard_/coming_soon/Coming_Soon";
import Dashboard_Popup from "./dashboard_/dashboard_popup/Dashboard_Popup";
import Pie_Chart from "./dashboard_/pie-chart/Pie_Chart";
import About_Us from "./about/about-us/About_Us";
import Myprofile from "./dashboard_/myprofile/Myprofile";
import EditProfile from "./dashboard_/myeditprofile/EditProfile";
import Life_Insurance_Home from "./dashboard_/life_insurance_home/Life_Insurance_Home";
import Life_Term_Insurance from "./dashboard_/life_term_insurance/Life_Term_Insurance";
import Family_Insurance from "./dashboard_/family_insurance/Family_Insurance";
import Retirement_Plan from "./dashboard_/retirement_plan/Retirement_Plan";
import Admin_dashboard from "./admin/admin_dashboard/Admin_dashboard";
import Admin_Deposite from "./admin/admin_deposite/Admin_Deposite";
import Pms_dashboard from "./admin/pms_dashboard/Pms_dashboard";

function App() {
  const cookies = new Cookies();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Check authentication state using cookies
  useEffect(() => {
    const username = cookies.get("username");
    if (username) {
      setIsAuthenticated(true);
    }
    setIsLoading(false); // Mark loading complete
  }, []);

  // Handle login
  const handleLogin = (username) => {
    setIsAuthenticated(true);
    cookies.set("username", username, { path: "/" });
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About_Us />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/signup" element={<Sign_up />} />

        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              element={<Dashboard_Main />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
        <Route
          path="/popup"
          element={
            <ProtectedRoute
              element={<Popup />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
        <Route
          path="/comingsoon"
          element={
            <ProtectedRoute
              element={<Coming_Soon />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
        <Route
          path="/dashboard_popup"
          element={
            <ProtectedRoute
              element={<Dashboard_Popup />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
        <Route
          path="/piechart"
          element={
            <ProtectedRoute
              element={<Pie_Chart />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
        <Route
          path="/myprofile"
          element={
            <ProtectedRoute
              element={<Myprofile />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
        <Route
          path="/editprofile"
          element={
            <ProtectedRoute
              element={<EditProfile />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />

        <Route
          path="/lifeinsurance"
          element={
            <ProtectedRoute
              element={<Life_Insurance_Home />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
        <Route
          path="/life-term"
          element={
            <ProtectedRoute
              element={<Life_Term_Insurance />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
        <Route
          path="/family-insurance"
          element={
            <ProtectedRoute
              element={<Family_Insurance />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
        <Route
          path="/retirement-plan"
          element={
            <ProtectedRoute
              element={<Retirement_Plan />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />

        {/* Admin Routes */}
        <Route
          path="/admindashboard"
          element={
            <ProtectedRoute
              element={<Admin_dashboard />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
        <Route
          path="/admin-deposit"
          element={
            <ProtectedRoute
              element={<Admin_Deposite />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
        <Route
          path="/pms-dashboard"
          element={
            <ProtectedRoute
              element={<Pms_dashboard />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
