import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Admin_dashboard.css";
import adminlogo from "../../assets/images/upholic.newlogo.png";
import Admin_sidebar_links from "../admin_sidebar_links/Admin_sidebar_links";

function Admin_dashboard() {
  const [selectedOption, setSelectedOption] = useState("ytd"); // Set default to 'ytd'
  const [date, setDate] = useState(""); // For storing selected date when 'Custom' is selected
  const [totalAmount, setTotalAmount] = useState(0); // To store the total amount fetched from the API
  const [message, setMessage] = useState(""); // To store API message
  const [userdepositeCount, setUserdepositeCount] = useState(0); // For user deposit count
  const [userCount, setUserCount] = useState(100); // Define userCount as a state (default value: 100)
  const [totalDepositSum, setTotalDepositSum] = useState(0); // State for Total Deposit Amount


  // Handle change in MTD/YTD dropdown
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // Handle change in date input (when 'Custom' is selected)
  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const fetchData = async () => {
    try {
      console.log(
        "Fetching data with options:",
        selectedOption,
        "and date:",
        date
      ); // Debugging log
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/admin_deposit_total.php",
        {
          option: selectedOption,
          date: selectedOption === "custom" ? date : "", // Only send date if custom is selected
        },
        {
          headers: {
            "Content-Type": "application/json", // Ensure the content type is set to application/json
          },
        }
      );

      console.log(response)
  
      // Set the response data to the state
      setTotalAmount(response.data.total_amount); // Debt Amount
      setUserdepositeCount(response.data.userdepositeCount); // Total Customer Count
      setTotalDepositSum(response.data.total_deposit_sum); // Deposit Amount
    } catch (error) {
      console.error("There was an error fetching the data:", error);
    }
  };

  // Use effect hook to trigger the fetchData when selectedOption or date changes
  useEffect(() => {
    if (selectedOption) {
      fetchData(); // Fetch data when selectedOption or date changes
    }
  }, [selectedOption, date]);

  return (
    <div>
      <Admin_sidebar_links />
      <div className="admin_dashboard_Card">
        <div className="admin_dashboard_area">
          <div className="admin_dashboard_container">
            <div className="admin_dashboard_logo_img">
              <img
                src={adminlogo}
                alt="Logo"
                className="admin_dashboard_image_logo"
              />
            </div>
            <div className="admin_dashboard_search_bar">
              <input type="text" placeholder="Search here" />
              <ion-icon name="search" className="search-icon"></ion-icon>
            </div>
            <div className="admin_dashboard_logo2_bar">
              <h1 className="admin_dashboard_page_heading">Dashboard</h1>
            </div>
          </div>

          {/* New Section with Date and MTD/YTD */}
          <div className="admin_dashboard_additionalInfo">
            <div className="admin_dashboard_mtd_ytd">
              <label htmlFor="mtd_ytd">Custom / MTD / YTD:</label>
              <select
                id="mtd_ytd"
                name="mtd_ytd"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="">Select</option>
                <option value="custom">Custom (Custom-to-Date)</option>
                <option value="mtd">MTD (Month-to-Date)</option>
                <option value="ytd">YTD (Year-to-Date)</option>
              </select>
            </div>

            {/* Show Date Input only when 'Custom' is selected */}
            {selectedOption === "custom" && (
              <div className="admin_dashboard_date">
                <label htmlFor="date">Date:</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={date}
                  onChange={handleDateChange}
                />
              </div>
            )}
          </div>

          {/* Cards */}
          <div className="admin_dashboard_deposit_cardBox">
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_numbers">
                  {userdepositeCount}
                </div>
                <div className="admin_dashboard_deposit_cardName">
                  Total Customer
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="people"></ion-icon>
              </div>
            </div>
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_numbers">{totalDepositSum > 0 ? `₹${totalDepositSum}` : "₹0"}</div>
                <div className="admin_dashboard_deposit_cardName">
                  Deposit Amt
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* PMS section  */}
      <div className="admin_dashboard_Card">
        <div className="admin_dashboard_area">
          <ul className="animated-circles">
            {Array(10)
              .fill()
              .map((_, index) => (
                <li key={index}></li>
              ))}
          </ul>

          {/* Cards */}
          <h1 className="admin_dashboard_card_name">PMS</h1>
          <div className="admin_dashboard_deposit_cardBox">
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Total Customer
                </div>
                <div className="admin_dashboard_deposit_numbers">
                  {userCount}
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="people"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">PMS Amt</div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Appreciated Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  revenue Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Life Insurance section  */}
      <div className="admin_dashboard_Card">
        <div className="admin_dashboard_area">
          <ul className="animated-circles">
            {Array(10)
              .fill()
              .map((_, index) => (
                <li key={index}></li>
              ))}
          </ul>

          {/* Cards */}
          <h1 className="admin_dashboard_card_name">Life Insurance</h1>
          <div className="admin_dashboard_deposit_cardBox">
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Total Customer
                </div>
                <div className="admin_dashboard_deposit_numbers">
                  {userCount}
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="people"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  INSURED Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  revenue Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  renewal Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Deposit  section */}
      <div className="admin_dashboard_Card">
        <div className="admin_dashboard_area">
          <ul className="animated-circles">
            {Array(10)
              .fill()
              .map((_, index) => (
                <li key={index}></li>
              ))}
          </ul>

          {/* Cards */}
          <h1 className="admin_dashboard_card_name">Deposit</h1>
          <div className="admin_dashboard_deposit_cardBox">
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Total Customer
                </div>
                <div className="admin_dashboard_deposit_numbers">
                  {userdepositeCount} {/* Display the userdepositeCount */}
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="people"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Deposit Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">{totalDepositSum > 0 ? `₹${totalDepositSum}` : "₹0"} {/* Show Total Deposit Sum */}</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">debt Amt</div>
                <div className="admin_dashboard_deposit_numbers">
                  {message && <p>{message}</p>}
                  {totalAmount > 0 ? `₹${totalAmount}` : "₹0"}{" "}
                  {/* Show total amount */}
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  revenue Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Stock adv section */}
      <div className="admin_dashboard_Card">
        <div className="admin_dashboard_area">
          <ul className="animated-circles">
            {Array(10)
              .fill()
              .map((_, index) => (
                <li key={index}></li>
              ))}
          </ul>

          {/* Cards */}
          <h1 className="admin_dashboard_card_name">Stock Adv</h1>
          <div className="admin_dashboard_deposit_cardBox">
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Total Customer
                </div>
                <div className="admin_dashboard_deposit_numbers">
                  {userCount}
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="people"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Stock Adv Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">debt Amt</div>
                <div className="admin_dashboard_deposit_numbers">₹ 0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  revenue Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹ 0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin_dashboard;
