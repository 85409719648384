import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "./Deposite_chart.css";

function Deposite_chart() {
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchDepositData() {
            try {
                // Fetch deposit data for the monthly total amount chart
                const depositAmountResponse = await axios.get(
                    "https://www.upholic.in/Upholic_Api/depositeamountchart.php?action=monthtotalamount"
                );
                
                // Fetch yearly interest data
                const yearlyInterestResponse = await axios.get(
                    "https://www.upholic.in/Upholic_Api/depositeyearlychart.php?action=yearlyinterest"
                );

                // Fetch monthly interest data
                const monthlyInterestResponse = await axios.get(
                    "https://www.upholic.in/Upholic_Api/depositemontlychart.php?action=monthlyinterest"
                );

                console.log("API Response (Deposit Amount):", depositAmountResponse.data);
                console.log("API Response (Yearly Interest):", yearlyInterestResponse.data);
                console.log("API Response (Monthly Interest):", monthlyInterestResponse.data);

                // Check if all responses are arrays
                if (
                    Array.isArray(depositAmountResponse.data) &&
                    Array.isArray(yearlyInterestResponse.data) &&
                    Array.isArray(monthlyInterestResponse.data)
                ) {
                    const depositData = depositAmountResponse.data;
                    const yearlyInterestData = yearlyInterestResponse.data;
                    const monthlyInterestData = monthlyInterestResponse.data;

                    // Sort the monthly interest data by month
                    const monthOrder = [
                        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                    ];

                    // Sort monthly interest data by the month name
                    monthlyInterestData.sort((a, b) => {
                        return monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month);
                    });

                    // Extract labels and deposit amounts for monthly total amount chart
                    const depositLabels = depositData.map((item) => item.month_year);
                    const depositAmounts = depositData.map((item) => item.total_amount);

                    // Extract labels and yearly interest amounts for the yearly interest chart
                    const yearlyLabels = yearlyInterestData.map((item) => item.year);
                    const yearlyInterest = yearlyInterestData.map(
                        (item) => item.total_yearly_interest
                    );

                    // Extract monthly interest data and convert string to float
                    const monthlyInterest = monthlyInterestData.map(
                        (item) => parseFloat(item.total_monthly_interest.replace(/,/g, '')) // Convert to float
                    );

                    // Data for the Deposit Amount chart
                    const depositAmountData = {
                        labels: depositLabels,
                        datasets: [
                            {
                                label: "Deposit Amount",
                                data: depositAmounts,
                                backgroundColor: "#4e2a84",
                                borderColor: "#2e1a53",
                                borderWidth: 1,
                                barPercentage: 0.9,
                                categoryPercentage: 0.5,
                            },
                        ],
                    };

                    // Monthly Interest Data for the Monthly Interest chart
                    const monthlyInterestDataChart = {
                        labels: monthlyInterestData.map((item) => item.month),
                        datasets: [
                            {
                                label: "Monthly Interest",
                                data: monthlyInterest,
                                backgroundColor: "#ff7f50",
                                borderColor: "#e65c3f",
                                borderWidth: 1,
                                barPercentage: 0.9,
                                categoryPercentage: 0.5,
                            },
                        ],
                    };

                    // Yearly Interest Data for the Yearly Interest chart
                    const yearlyInterestDataChart = {
                        labels: yearlyLabels,
                        datasets: [
                            {
                                label: "Yearly Interest",
                                data: yearlyInterest,
                                backgroundColor: "#4caf50",
                                borderColor: "#388e3c",
                                borderWidth: 1,
                                barPercentage: 0.3,
                                categoryPercentage: 0.5,
                            },
                        ],
                    };

                    // Set chart data state
                    setChartData({
                        depositAmountData,
                        monthlyInterestDataChart,
                        yearlyInterestDataChart,
                    });
                } else {
                    throw new Error("Unexpected response format");
                }

                setLoading(false);
            } catch (err) {
                setError("Error fetching data: " + err.message);
                setLoading(false);
            }
        }

        fetchDepositData();
    }, []);

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">{error}</div>;

    // Chart options configuration
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        layout: {
            padding: {
                bottom: 20,
            },
        },
        plugins: {
            legend: {
                labels: {
                    font: {
                        size: 14,
                    },
                },
            },
            tooltip: {
                enabled: true,
                mode: "index",
                intersect: false,
            },
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 12,
                    },
                },
                title: {
                    display: true,
                    text: "Month/Year",
                    font: {
                        size: 16,
                    },
                },
            },
            y: {
                ticks: {
                    beginAtZero: true,
                    font: {
                        size: 12,
                    },
                },
                title: {
                    display: true,
                    text: "Amount",
                    font: {
                        size: 16,
                    },
                },
            },
        },
    };

    return (
        <div className="deposite-chart-main-container">
            {/* Deposit Amount Chart */}
            <div className="deposite-chart-container">
                <h2>Deposit Amount</h2>
                <Bar data={chartData.depositAmountData} options={options} className="chart" />
            </div>

            {/* Monthly Interest Chart */}
            <div className="deposite-chart-container">
                <h2>Monthly Interest Paid</h2>
                <Bar data={chartData.monthlyInterestDataChart} options={options} className="chart" />
            </div>

            {/* Yearly Interest Chart */}
            <div className="deposite-chart-container">
                <h2>Yearly Interest Paid</h2>
                <Bar data={chartData.yearlyInterestDataChart} options={options} className="chart" />
            </div>
        </div>
    );
}

export default Deposite_chart;
