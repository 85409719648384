import React from "react";
import { Link, useNavigate } from "react-router-dom";  // Import useNavigate here
import Cookies from "universal-cookie";
import "./Admin_sidebar_links.css";
import axios from "axios";

function Admin_sidebar_links({ onLogout }) {
  const navigate = useNavigate(); // Use useNavigate hook to get navigate function

  // Logout function
  const handleLogout = async (event) => {
    event.preventDefault(); // Prevent the default behavior of anchor tag
    console.log("Logout function invoked"); // Check if the function is triggered

    const cookies = new Cookies();

    try {
      const response = await axios.get(
        "https://www.upholic.in/Upholic_Api/logout.php?logout=true",
        {
          withCredentials: true, // Send cookies with the request
        }
      );
      console.log("Logout Response:", response.data); // Log the response

      if (response.data.message === "Logout successful bye") {
        // Clear the cookies
        cookies.remove("username", { path: "/" });

        // Call the parent function to update the auth state
        if (onLogout && typeof onLogout === "function") {
          onLogout();
        }

        // Redirect to the login page using navigate
        navigate("/login");
      }
    } catch (error) {
      console.error("Error during logout:", error); // Log the error if any
    }
  };
  
  return (
    <>
      <div className="side_side">
        <div className="sidebar-container">
          <ul className="animated-circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>

          <nav>
            <ul>
              <div className="icon-wrapper">
                <li className="icon-item">
                  <Link to="/admindashboard" className="icon-link">
                    <ion-icon className="icon-class" name="home-outline"></ion-icon>
                    <span className="icon-tooltip">Home</span>
                  </Link>
                </li>
              </div>
              <div className="icon-wrapper">
                <li className="icon-item">
                  <Link to="/pms-dashboard" className="icon-link">
                    <ion-icon className="icon-class" name="people"></ion-icon>
                    <span className="icon-tooltip">PMS</span>
                  </Link>
                </li>
              </div>
              <div className="icon-wrapper">
                <li className="icon-item">
                  <a href="#services">
                    <ion-icon
                      className="icon-class"
                      name="clipboard"
                    ></ion-icon>
                  </a>
                </li>
              </div>
              <div className="icon-wrapper">
                <li className="icon-item">
                  <Link to="/admin-deposit" className="icon-link">
                    <ion-icon className="icon-class" name="wallet"></ion-icon>
                    <span className="icon-tooltip">Deposit</span>
                  </Link>
                </li>
              </div>
              <div className="icon-wrapper">
                <li className="icon-item">
                  <a href="#contact">
                    <ion-icon
                      className="icon-class"
                      name="stats-chart-outline"
                    ></ion-icon>
                  </a>
                </li>
              </div>
              <div className="icon-wrapper">
                <li className="icon-item">
                  <a href="#contact" className="icon-link" onClick={handleLogout}>
                    <ion-icon className="icon-class" name="log-out"></ion-icon>
                    <span className="icon-tooltip">Logout</span>
                  </a>
                </li>
              </div>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Admin_sidebar_links;