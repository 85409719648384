import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Pms_login_data.css";

function Pms_login_data() {
  const [formData, setFormData] = useState({
    userId: "",
    fullname: "",
    username: "",
    phone: "",
    email: "",
    password: "",
    service: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [accordionOpen, setAccordionOpen] = useState(false);

  // Fetch user data when userId or phone changes
  useEffect(() => {
    const fetchUserData = async () => {
      if (!formData.userId && !formData.phone) return;

      try {
        const response = await axios.post(
          "https://www.upholic.in/Upholic_Api/fetch_all_data.php",
          {
            userId: formData.userId || null,
            phoneNumber: formData.phone || null,
          },
          { headers: { "Content-Type": "application/json" } }
        );

        const responseData = response.data;

        if (responseData.success && responseData.user) {
          setFormData((prevData) => ({
            ...prevData,
            userId: responseData.user.Id,
            fullname: responseData.user.Name,
            username: responseData.user.Username,
            phone: responseData.user["Phone-Number"],
            email: responseData.user["E-Mail"],
            password: "",
            service: responseData.user["Our-Services"]?.trim() || "",
          }));
          setErrorMessage("");
        } else {
          setErrorMessage("User not found.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setErrorMessage("An error occurred while fetching user data.");
      }
    };

    fetchUserData();
  }, [formData.userId, formData.phone]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone" && value.length > 10) {
      setErrorMessage("Phone number must be exactly 10 digits.");
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if ((name === "userId" || name === "phone") && value === "") {
      setFormData({
        userId: "",
        fullname: "",
        username: "",
        phone: "",
        email: "",
        password: "",
        service: "",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.phone.length !== 10) {
      setErrorMessage("Phone number must be exactly 10 digits.");
      return;
    }

    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/signup.php",
        {
          name: formData.fullname,
          username: formData.username,
          phoneNumber: formData.phone,
          email: formData.email,
          password: formData.password,
          service: formData.service,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = response.data;

      if (responseData.success) {
        setSuccessMessage("Record inserted successfully!");
        setErrorMessage("");
        setFormData({
          userId: "",
          fullname: "",
          username: "",
          phone: "",
          email: "",
          password: "",
          service: "",
        });
      } else {
        setErrorMessage(responseData.message);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setErrorMessage("An error occurred. Please try again later.");
    }
  };

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  return (
    <div className="viewall-container">
      <div className="section">
        <div className="section-title" onClick={toggleAccordion}>
          Insert User Login Data
        </div>
        {accordionOpen && (
          <>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}

            <form className="pms_login_entry-form" onSubmit={handleSubmit}>
              <div className="pms_login_entry-row">
                <label>User ID:</label>
                <input
                  type="text"
                  name="userId"
                  value={formData.userId}
                  onChange={handleInputChange}
                />
              </div>
              <div className="pms_login_entry-row">
                <label>Phone number:</label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
              <div className="pms_login_entry-row">
                <label>Name:</label>
                <input
                  type="text"
                  name="fullname"
                  value={formData.fullname}
                  onChange={handleInputChange}
                />
              </div>
              <div className="pms_login_entry-row">
                <label>Username:</label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                />
              </div>
              <div className="pms_login_entry-row">
                <label>Email:</label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="pms_login_entry-row">
                <label>Password:</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </div>
              <div className="pms_login_entry-row">
                <label>Our Service:</label>
                <select
                  name="service"
                  value={formData.service}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="PMS">PMS</option>
                  <option value="LI">LI</option>
                  <option value="Investment">Investment</option>
                  {/* <option value="Stock-Advisory">ChartSmart</option> */}
                </select>
              </div>
              <div className="investment-row">
                <button type="submit" className="edit-button">
                  Insert
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default Pms_login_data;
