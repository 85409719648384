import React from "react";
import "./Carousel.scss"; // Import your existing CSS file
import investmentvid from '../../assets/video/upholicinvestment.vid.mp4'; // Ensure the video is optimized for web

function Carousel() {
  return (
    <>
    <div className="carousel-container">
      <div className="video-wrapper">
        <video autoPlay loop muted>
          <source src={investmentvid} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
        <div className="gradient-overlay"></div>
      </div>
      <div className="content">
        <div className="subtitle">Invest with Confidence</div>
        <h1 className="main-title">
          "Smart investments today pave the way for a prosperous tomorrow."
        </h1>
        <button className="cta-button">Learn More</button>
      </div>
    </div>
  </>
  );
}

export default Carousel;