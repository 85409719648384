import React, { useState, useEffect } from "react";
import "./Sign_up.scss";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { ImCross } from "react-icons/im";

function Sign_up() {
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    name: "",
    username: "",
    phoneNumber: "",
    email: "",
    password: "",
    service: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // New state for success message

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const service = queryParams.get("service");
    if (service) {
      setFormData((prevFormData) => ({ ...prevFormData, service }));
    }
  }, [location.search]);

  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value;
    if (/^\d{0,10}$/.test(inputPhoneNumber)) {
      setFormData({ ...formData, phoneNumber: inputPhoneNumber });
    }
  };

  const isValidName = (name) => {
    return /^[a-zA-Z\s]*$/.test(name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(formData).some((value) => value === "")) {
      setErrorMessage("Please fill out all required fields.");
      return;
    }

    if (formData.phoneNumber.length !== 10) {
      setErrorMessage("Phone number must be exactly 10 digits.");
      return;
    }

    if (formData.password.length < 8) {
      setErrorMessage("Password must be at least 8 characters long.");
      return;
    }

    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/signup.php",
        formData
      );

      const responseData = response.data;

      if (responseData.success) {
        const cookies = new Cookies();
        cookies.set("username", formData.username, { path: "/" });

        setSuccessMessage("Sign-up successful! Redirecting..."); // Set success message

        setTimeout(() => {
          switch (formData.service) {
            case "PMS":
              navigate("/dashboard");
              break;
            // case "Stock_advisory":
            //   navigate("/tradingsoftware");
            //   break;
            case "LI":
              navigate("/dashboard");
              break;
            case "Deposit":
              navigate("/dashboard");
              break;
            default:
              navigate("/");
              break;
          }
        }, 2000); // Redirect after 2 seconds
      } else {
        setErrorMessage(responseData.message);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setErrorMessage("An error occurred. Please try again later.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "name" && !isValidName(value)) {
      setErrorMessage("Full Name can only contain alphabets and spaces.");
      return;
    }

    setFormData({ ...formData, [name]: value });
    setErrorMessage("");
  };

  return (
    <>
      <div className="signup-body">
        <Link to="/" className="close-link">
          <ImCross className="close-icon" />
        </Link>
        <div className="signup-container">
          <div className="signup-content">
            <div className="signup-content_header">
              <h2>Sign-Up</h2>
            </div>
            <div>
              <form className="signup-form form-list" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  placeholder="Username"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  required
                />
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                <div className="dropdown">
                  <select
                    name="service"
                    value={formData.service}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Our Services
                    </option>
                    <option value="PMS">PMS</option>
                    <option value="Deposit">Investment</option>
                    <option value="LI">Life insurance</option>
                    {/* <option value="Stock_advisory">Stock Advisory</option> */}
                  </select>
                </div>

                <input type="submit" value="Sign Up" />
              </form>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              {successMessage && (
                <div className="success-popup">{successMessage}</div> // Popup container
              )}
              <p>
                By creating an account you agree to Upholic{" "}
                <strong>Terms of Services</strong> and{" "}
                <strong>Privacy Policy.</strong>
              </p>
            </div>
          </div>
          <div className="signup-footer"></div>
        </div>
      </div>
    </>
  );
}

export default Sign_up;