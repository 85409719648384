import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import "./Login.scss"; // Assuming you have SCSS styles for better design

const Login = ({ onLogin }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const cookies = new Cookies();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation check
    if (!formData.username || !formData.password) {
      setError("Please enter both username and password");
      return;
    }

    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/login.php",
        JSON.stringify({
          username: formData.username,
          password: formData.password,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.success) {
        cookies.set("username", formData.username, { path: "/" });
        const userId = response.data.user_id;
        cookies.set("id", userId, { path: "/" });

        // Call the onLogin function passed from App.js to update isAuthenticated
        onLogin(formData.username);

        // Redirect based on user type
        if (response.data.isAdmin) {
          navigate("/admindashboard");
        } else {
          navigate("/dashboard");
        }
      } else {
        setError(response.data.message || "Invalid username or password");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="login-body">
      <Link to="/" className="close-link">
        <ImCross className="close-icon" />
      </Link>
      <div className="login-container">
        <div className="login-content">
          <div className="login-content_header">
            <h2>Welcome Back</h2>
          </div>
          <div>
            <form className="login-form" onSubmit={handleSubmit}>
              <input
                type="text"
                name="username"
                placeholder="Username"
                required
                value={formData.username}
                onChange={handleInputChange}
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                required
                value={formData.password}
                onChange={handleInputChange}
              />
              {error && <p className="error-message">{error}</p>}
              <input type="submit" value="Login" />
            </form>
            <p>
              By logging in, you agree to Upholic{" "}
              <strong>Terms of Services</strong> and{" "}
              <strong>Privacy Policy.</strong>
            </p>
          </div>
        </div>
        <div className="login-footer"></div>
      </div>
    </div>
  );
};

export default Login;